import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, types, endpoints} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {EmployeeNameWithAvatar, Layout, InteractiveTable, PanelTooltip} from 'components';
import {NmbrDirectoryEmployee} from 'stores/nmbr';
import EnrolEmployeeModal from './EnrolEmployeeModal';
import useModal from 'shared/hooks/useModal';
import {NmbrEmployeesDirectoryState} from '../state';
import BatchEnrolEmployeesModal from './BatchEnrolEmployeesModal';
import BatchEnrolEmployeesResultsModal from './BatchEnrolEmployeesResultsModal';
import _ from 'lodash';

const NmbrStatus = observer(({model}) => {
  if (model.configured) {
    return (
      <div className='flex align-items-middle'>
        <i className='material-icons grass mr1 h2'>{'done'}</i>
        <FormattedMessage id='nmbr.employees.directory.Enrolled'/>
      </div>
    );
  }

  if (!_.isEmpty(model.missingFields)) {
    return (
      <div className='flex align-items-middle'>
        <PanelTooltip
          icon={<i className='material-icons sun mr1 mln1 h2'>{'warning'}</i>}
        >
          <div>
            <div className='mb1'><FormattedMessage id='nmbr.employees.directory.The following fields have not been filled out for this employee:'/></div>
            {model.missingFields.map(field => <li className='ml2'>
              <FormattedMessage id={`nmbr.employees.directory.missing_fields.${field}`}/>
            </li>)}
          </div>
        </PanelTooltip>
        <FormattedMessage id='nmbr.employees.directory.Missing Info'/>
      </div>
    );
  }

  return (
    <div className='flex align-items-middle'>
      <i className='material-icons waterloo mr1 h2'>{'horizontal_rule'}</i>
      <FormattedMessage id='nmbr.employees.directory.Not Enrolled'/>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'nmbr.employees.directory.Employee',
    render: model => <EmployeeNameWithAvatar employee={model} size='sm'/>,
    width: 8,
    sortBy: 'name'
  },
  {
    header: 'nmbr.employees.directory.Status',
    component: NmbrStatus,
    width: 3
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'nmbr.employees.directory.Batch Enrol Employees',
  },
  emptyState: 'mass_updates.directory.No employees to display'
};

const NmbrEmployeesDirectoryWithState = observer(({uiState}) => {
  const enrolEmployeeModalOpen = useModal(uiState, 'EnrolEmployeeModal', 'selectedEmployee', null, 'errors');
  const batchEnrolEmployeesModalOpen = useModal(uiState, 'BatchEnrolEmployeesModal');
  const batchEnrolEmployeesResultsModalOpen = useModal(uiState, 'BatchEnrolEmployeesResultsModal');

  return (
    <Layout>
      <InteractiveTable
        title={t('nmbr.employees.directory.Employees')}
        onAdd={() => uiState.openBatchEnrolEmployeesModal()}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        searchable={false}
        saveStateToUrl
        onMount={agent => uiState.setInteractiveAgent(agent)}
        customLinks={model => uiState.customLinksFor(model)}
        proxy={{
          type: 'async',
          modelType: types.EMPLOYEE,
          model: NmbrDirectoryEmployee,
          endpoint: endpoints.NMBR.EMPLOYEES.ALL
        }}
      />
      <EnrolEmployeeModal uiState={uiState} modalOpen={enrolEmployeeModalOpen}/>
      <BatchEnrolEmployeesModal uiState={uiState} modalOpen={batchEnrolEmployeesModalOpen}/>
      <BatchEnrolEmployeesResultsModal uiState={uiState} modalOpen={batchEnrolEmployeesResultsModalOpen}/>
    </Layout>
  );
});

export default withState(NmbrEmployeesDirectoryWithState, NmbrEmployeesDirectoryState);
