import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {endpoints, types, t, api} from 'shared/core';
import {successAlert} from 'shared/tools';
import {NmbrPaySchedule} from 'stores/nmbr';
import _ from 'lodash';
import {Employee} from 'stores/employees';

class NmbrEmployeesDirectoryState {
  interactiveAgent;
  store = new DomainStore();

  @observable paySchedules = [];

  @observable selectedEmployee;
  @observable selectedEmployees;
  @observable selectedPayScheduleId;
  @observable enrolInProgress = false;
  @observable batchEnrolmentResult;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.NMBR.PAY_SCHEDULES.ALL
    ]);

    this.paySchedules = this.store._getAll(
      types.NMBR.PAY_SCHEDULE,
      NmbrPaySchedule
    );
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async enrolEmployee() {
    const {model, errors} = await this.store.post(
      endpoints.NMBR.EMPLOYEES.ENROL.with(this.selectedEmployee.id, this.selectedPayScheduleId),
      types.EMPLOYEE
    );

    this.errors = errors;
    if (model) {
      this.closeEnrolEmployeeModal();
      successAlert(t('nmbr.employees.directory.EMPLOYEE_ENROLLED', { name: this.selectedEmployee.name }));
      this.interactiveAgent.refresh();
    }
  }

  @action updateSelectedPayScheduleId(payScheduleId) {
    this.selectedPayScheduleId = payScheduleId;
  }

  @action async performBatchEnrolment() {
    this.enrolInProgress = true;

    const {data} = await api.post(
      endpoints.NMBR.EMPLOYEES.BATCH_ENROL_EMPLOYEES,
      {
        employeeIds: _.map(this.selectedEmployees, 'id'),
        nmbrPayScheduleId: this.selectedPayScheduleId
      }
    );

    this.batchEnrolmentResult = _.map(data.data, employee => new Employee({id: employee.id, ...employee.attributes}));
    this.interactiveAgent.refresh();
    this.enrolInProgress = false;
  }

  @action batchEnrolEmployees() {
    this.closeBatchEnrolEmployeesModal();
    this.openBatchEnrolEmployeesResultsModal();
    this.performBatchEnrolment();
  }

  @action async unenrolEmployee(employee) {
    const {model} = await this.store.post(
      endpoints.NMBR.EMPLOYEES.UNENROL.with(employee.id),
      types.EMPLOYEE
    );

    if (model) {
      successAlert(t('nmbr.employees.directory.EMPLOYEE_UNENROLLED', { name: this.selectedEmployee.name }));
    }
  }

  @computed get enrolledEmployees() {
    return _.filter(this.batchEnrolmentResult, employee => !employee.errorMessage);
  }

  @computed get erroredEmployees() {
    return _.filter(this.batchEnrolmentResult, employee => employee.errorMessage);
  }

  customLinksFor(employee) {
    return [{
      order: 0,
      text: employee.nmbrPayrollEnabled ? 'nmbr.employees.directory.Unenrol from nmbr' : 'nmbr.employees.directory.Enrol in nmbr',
      action: () => employee.nmbrPayrollEnabled ? this.unenrolEmployee(employee) : this.openEnrolEmployeeModal(employee)
    }];
  }
}

export default NmbrEmployeesDirectoryState;
