import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {DatePicker, EmailTemplatePreview} from 'components';

const RelaunchSurveyModal = observer(({uiState}) => {
  const {selectedSurvey, welcomeEmailPreview} = uiState;

  if (!selectedSurvey) return null;

  return (
    <Modal
      size='md'
      title='surveys.directory.relaunch_survey.Relaunch Survey'
      isOpen={uiState.relaunchSurveyModalOpen}
      onHide={() => uiState.closeRelaunchSurveyModal()}
    >
      <div className='mb2'>
        <FormattedMessage id='surveys.directory.relaunch_survey.LAST_LAUNCHED' values={{lastLaunched: selectedSurvey.launchedAtView}}/>
      </div>
      <div className='mb2'>
        <FormattedMessage id='surveys.directory.relaunch_survey.MODAL_EXPLAINER'/>
      </div>
      <DatePicker
        value={selectedSurvey.dueDate}
        options={{startDate: '0'}}
        onChange={date => selectedSurvey.merge({dueDate: date})}
        label='surveys.directory.relaunch_survey.Due date'
        optional={true}
      />
      <div className='mt2 mb1 medium'>
        <FormattedMessage id='surveys.directory.relaunch_survey.Relaunch email preview'/>
      </div>
      <div className='clearfix pb2 jumbo'>
        <FormattedMessage id='surveys.directory.relaunch_survey.RELAUNCH_TO' values={{count: selectedSurvey.totalStatusCount}}/>
      </div>
      <EmailTemplatePreview template={welcomeEmailPreview}/>
      <ModalButtons
        onSave={() => uiState.relaunchSurvey()}
        onCancel={() => uiState.closeRelaunchSurveyModal()}/>
    </Modal>
  );
});

export default RelaunchSurveyModal;
