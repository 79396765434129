import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';
import {redirect} from 'shared/tools';

const FillerPositionText = ({staffCount, distinctJobTypes}) => {
  if (distinctJobTypes >= 2) return null;

  if (distinctJobTypes === 1) {
    if (!staffCount.fullTime) return (
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.FULL_TIME'
          values={{num: 0}}
        />
      </span>
    );

    if (!staffCount.partTime) return (
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.PART_TIME'
          values={{num: 0}}
        />
      </span>
    );
  }

  return (
    <span>
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.FULL_TIME'
          values={{num: 0}}
        />
      </span>
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.PART_TIME'
          values={{num: 0}}
        />
      </span>
    </span>
  );
};

const PositionText = ({positionCount, positionID}) => {
  if (!positionCount) return null;

  const url = `employees/directory?f-employment_type=${positionID.toLowerCase()}`;

  return (
    <span className='employment-type-count types'>
      <ActionLink subdued href={url} onClick={() => redirect(url)}>
        <FormattedMessage
          id={`hr_dashboard.staff_count.${positionID}`}
          values={{num: positionCount}}
        />
      </ActionLink>
    </span>
  );
};

const StaffCount = observer(({staffCount, distinctJobTypes}) => {
  if (!auth.user.staffCountVisibility) {
    return null;
  }

  return (
    <div className='StaffCount pt1'>
      <div>
        <span className='employment-type-count active-staff mr1'><FormattedMessage id='hr_dashboard.staff_count.ACTIVE_STAFF' values={{num: staffCount.active}}/></span>
        {staffCount.onLeave > 0 && <span className='employment-type-count'><FormattedMessage id='hr_dashboard.staff_count.ON_LEAVE_STAFF' values={{num: staffCount.onLeave}}/></span>}
      </div>
      <PositionText positionCount={staffCount.fullTime} positionID='FULL_TIME'/>
      <PositionText positionCount={staffCount.partTime} positionID='PART_TIME'/>
      <PositionText positionCount={staffCount.temp} positionID='TEMP'/>
      <PositionText positionCount={staffCount.intern} positionID='INTERN'/>
      <PositionText positionCount={staffCount.volunteer} positionID='VOLUNTEER'/>
      <PositionText positionCount={staffCount.contractor} positionID='CONTRACTOR'/>
      <PositionText positionCount={staffCount.seasonal} positionID='SEASONAL'/>
      <PositionText positionCount={staffCount.casual} positionID='CASUAL'/>
      <FillerPositionText staffCount={staffCount} distinctJobTypes={distinctJobTypes}/>
    </div>
  );
});

export default StaffCount;
