import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeSelector, FormLayout, Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';

const BatchEnrolEmployeesModal = observer(({modalOpen, uiState}) => {
  const {paySchedules, selectedPayScheduleId, errors} = uiState;

  return (
    <Modal
      size='md'
      title={'nmbr.employees.directory.Batch Enrol Employees'}
      isOpen={modalOpen}
    >
      <FormLayout>
        <Select2
          className='mt2'
          label='nmbr.employees.directory.Choose the pay schedule for these employees'
          value={selectedPayScheduleId}
          onChange={e => uiState.updateSelectedPayScheduleId(e.target.value)}
          errorMessage={errors.nmbrPayScheduleId}
        >
          {paySchedules.map(paySchedule => <option value={paySchedule.id} key={paySchedule.title}>{paySchedule.title}</option>)}
        </Select2>
        <div>
          <FormattedMessage id='nmbr.employees.directory.Choose which employees to enrol'/>
          <EmployeeSelector onChange={employees => uiState.selectedEmployees = employees}/>
        </div>
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.batchEnrolEmployees()}
        saveCaption={'nmbr.employees.directory.Enrol'}
        onCancel={() => uiState.closeBatchEnrolEmployeesModal()}
      />
    </Modal>
  );
});

export default BatchEnrolEmployeesModal;
