const STEPS = [
  {
    name: 'employees.hire.steps.Employment',
    location: 'employment',
  },
  {
    name: 'employees.hire.steps.Compensation',
    location: 'compensation'
  },
  {
    name: 'employees.hire.steps.Custom Fields',
    location: 'custom_fields',
    useCustomLayout: true
  },
  {
    name: 'employees.hire.steps.Job Custom Fields',
    location: 'job_custom_fields',
    useCustomLayout: true
  },
  {
    name: 'employees.hire.steps.Payroll',
    location: 'payroll'
  },
  {
    name: 'employees.hire.steps.Benefits',
    location: 'benefits'
  },
  {
    name: 'employees.hire.steps.Banking & Tax',
    location: 'banking',
  },
  {
    name: 'employees.hire.steps.Documents',
    location: 'documents',
  },
  {
    name: 'employees.hire.steps.Training',
    location: 'training',
  },
  {
    name: 'employees.hire.steps.Onboarding Tasks',
    location: 'tasks',
  },
  {
    name: 'employees.hire.steps.Review',
    location: 'review',
    useCustomLayout: true
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

export default STEPS;
