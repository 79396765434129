import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Input, DatePicker, FormLayout, Select2} from 'components';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';

const PayScheduleForm = observer(({uiState}) => {
  const {
    isCreatingNewPaySchedule,
    selectedPaySchedule,
    newPayPeriod,
    errors
  } = uiState;

  if (!isCreatingNewPaySchedule) return null;

  return (
    <div>
      <Panel.Separator/>
      <FormLayout>
        <Input
          label='time_tracking.manage.policies.edit.Name'
          value={selectedPaySchedule.name}
          onChange={e => selectedPaySchedule.name = e.target.value}
          placeholder='time_tracking.manage.policies.edit.e.g. Main Schedule'
          errorMessage={errors['paySchedule.name']}
        />
        <div className='jumbo'>
          <FormattedMessage id='time_tracking.manage.policies.edit.PAY_SCHEDULE_EXPLANATION' />
        </div>
        <FormLayout.Group>
          <DatePicker
            label='time_tracking.manage.policies.edit.Start Date'
            value={newPayPeriod.startDate}
            onChange={date => {
              newPayPeriod.merge({startDate: date});
              uiState.calculatePayPeriods();
            }}
            errorMessage={errors['paySchedule.payPeriods.startDate']}
            options={
              {
                maxViewMode: 'months',
                startDate: moment().subtract(1, 'year').toDate(),
                endDate: moment().toDate(),
              }
            }
          />
          <Select2
            label='time_tracking.manage.policies.edit.Frequency'
            value={selectedPaySchedule.frequency}
            onChange={e => {
              selectedPaySchedule.frequency = e.target.value;
              uiState.calculatePayPeriods();
            }}
            errorMessage={errors['paySchedule.frequency']}
          >
            <option value='weekly'>{t('time_tracking.manage.policies.edit.Weekly')}</option>
            <option value='biweekly'>{t('time_tracking.manage.policies.edit.Bi-weekly')}</option>
            <option value='monthly'>{t('time_tracking.manage.policies.edit.Monthly')}</option>
            <option value='semimonthly'>{t('time_tracking.manage.policies.edit.Semi-monthly')}</option>
          </Select2>
        </FormLayout.Group>
      </FormLayout>
    </div>
  );
});

export default PayScheduleForm;
