import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Select2, TextArea, Panel, Input, RadioButton, RadioButtonGroup, CheckboxWithLabel} from 'components';
import {FormattedMessage} from 'react-intl';
import cc from 'currency-codes';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';
import PAY_FREQUENCY_TYPES from 'stores/employees/payFrequencyTypes';
import {t, auth} from 'shared/core';
import CommissionForm from 'employees/components/CommissionForm';

const PayrollEnabledSection = observer(({uiState}) => {
  const {employee} = uiState;

  if (!auth.company.payrollEnabled) return null;

  return (
    <div>
      <Panel.Separator/>
      <Panel.Header title={t('employees.hire.compensation.Payroll')}/>
      <RadioButtonGroup
        label='employees.profile.role.Include this employee in payroll?'
        name='payrollEnabled'
        selectedValue={employee.payrollEnabled}
        onChange={(name, value) => employee.merge({payrollEnabled: value})}
      >
        <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
        <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
      </RadioButtonGroup>
    </div>
  );
});

const NmbrPayrollSection = observer(({uiState}) => {
  const {employee, paySchedules, errors} = uiState;

  if (!auth.company.nmbrEnabled) return null;

  return (
    <div>
      <Panel.Separator/>
      <Panel.Header title={t('employees.hire.compensation.Payroll')}/>
      <RadioButtonGroup
        label='employees.profile.role.Include this employee in payroll?'
        name='nmbrPayrollEnabled'
        selectedValue={employee.nmbrPayrollEnabled}
        onChange={(name, value) => employee.merge({nmbrPayrollEnabled: value})}
      >
        <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
        <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
      </RadioButtonGroup>
      {employee.nmbrPayrollEnabled && <div className='mt2'>
        <Select2
          label='employees.hire.compensation.Pay Schedule'
          value={employee.nmbrPayScheduleId}
          onChange={e => employee.merge({nmbrPayScheduleId: e.target.value})}
          errorMessage={errors.nmbrPayScheduleId}
        >
          {paySchedules.map(paySchedule => <option value={paySchedule.id} key={paySchedule.title}>{paySchedule.title}</option>)}
        </Select2>
      </div>}
    </div>
  );
});

const Compensation = observer(({uiState}) => {
  const {employee, errors, skipHoursPerWeek} = uiState;

  return (
    <div>
      <Panel.Header title={t('employees.hire.compensation.Compensation Info')}/>
      <FormLayout>
        <Input
          label='employees.profile.role.Salary (or pay rate)'
          value={employee.payRate}
          onChange={e => employee.merge({payRate: e.target.value})}
          errorMessage={errors.payRate || errors.payRateUnit}
          connectedRight={
            <Select2
              value={employee.payRateUnit}
              onChange={e => employee.merge({payRateUnit: e.target.value})}
            >
              {PAY_RATE_UNIT_TYPES.map(p => <option value={p.id} key={p.id}>{t(`employees.profile.role.per_unit.${p.id}`)}</option>)}
            </Select2>
          }
        />
        <FormLayout.Group>
          <Select2
            label='employees.profile.role.Pay Currency'
            value={employee.payRateCurrency}
            onChange={e => employee.merge({payRateCurrency: e.target.value})}
            errorMessage={errors.payRateCurrency}
            searchable
          >
            {cc.codes().map(c => <option key={c} value={c}>{c}</option>)}
          </Select2>
          <Select2
            label='employees.profile.role.Pay Frequency'
            value={employee.payFrequency}
            onChange={e => employee.merge({payFrequency: e.target.value})}
            allowClear
            helpLink={{
              explanation: {
                header: t('employees.profile.role.pay_frequency_explanation.header'),
                paragraphs: [
                  t('employees.profile.role.pay_frequency_explanation.explanation_1', {employee: employee.name}),
                  t('employees.profile.role.pay_frequency_explanation.explanation_2')
                ]
              }
            }}
          >
            {PAY_FREQUENCY_TYPES.map(p => <option value={p.id} key={p.id}>{t(p.name)}</option>)}
          </Select2>
        </FormLayout.Group>
        <Input
          label='employees.profile.role.Hours worked per week'
          helpLink={{
            explanation: {
              header: 'employees.profile.role.hours_per_week_explanation_header',
              paragraphs: [
                t('employees.profile.role.hours_per_week_explanation_1', {employee: employee.name}),
                t('employees.profile.role.hours_per_week_explanation_2')
              ]
            }
          }}
          value={employee.hoursPerWeek}
          onChange={e => employee.merge({hoursPerWeek: e.target.value})}
          errorMessage={errors.hoursPerWeek}
          type='number'
          disabled={skipHoursPerWeek}
        />
        <CheckboxWithLabel
          checked={skipHoursPerWeek}
          onChange={e => uiState.toggleSkipHoursPerWeek(e.checked)}
          label='employees.profile.role.This employee does not work a set number of hours per week'
        />
        <RadioButtonGroup
          label='employees.profile.role.Employee earns a bonus'
          name='bonus'
          selectedValue={employee.bonus}
          onChange={(name, value) => employee.merge({bonus: value})}
        >
          <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
          <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
        </RadioButtonGroup>
        {employee.bonus &&
          <TextArea
            label='employees.profile.role.Bonus Details'
            value={employee.bonusStructure}
            onChange={(e) => employee.merge({bonusStructure: e.target.value})}
            placeholder='employees.profile.role.Enter the bonus details here'
            className='full-width' minRows={3}
          />
        }
        <CommissionForm model={employee} errors={errors} />
        <PayrollEnabledSection uiState={uiState}/>
        <NmbrPayrollSection uiState={uiState}/>
      </FormLayout>
    </div>
  );
});

export default Compensation;
