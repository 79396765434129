import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const TaxFormCompleted = observer(({nmbrUpdate}) => {
  return (
    <div>
      <FormattedMessage
        id={`nmbr.updates.directory.${nmbrUpdate.eventType}.EXPLAINER`}
        values={{formName: _.get(nmbrUpdate.fieldUpdates, '[0].formName'), claimAmount: _.get(nmbrUpdate.fieldUpdates, '[0].value')}}
      />
    </div>
  );
});

export default TaxFormCompleted;
