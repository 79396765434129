import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';

const EmployeeReturned = observer(({nmbrUpdate}) => {
  return (
    <div>
      <FormattedMessage
        id={`nmbr.updates.directory.${nmbrUpdate.eventType}.EXPLAINER`}
        values={{effectiveDate: calendarDate(nmbrUpdate.effectiveDate), payRate: _.get(nmbrUpdate.fieldUpdates, '[0].newRate')}}
      />
    </div>
  );
});

export default EmployeeReturned;
