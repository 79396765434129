import React from 'react';
import {observer} from 'mobx-react';
import EmployeeEnrolled from './EmployeeEnrolled';
import EmployeeUpdated from './EmployeeUpdated';
import PayRateUpdated from './PayRateUpdated';
import RoeRequired from './RoeRequired';
import EmployeeReturned from './EmployeeReturned';
import TaxFormCompleted from 'containers/nmbr/components/updates/components/TaxFormCompleted';

const NmbrUpdateFactory = observer(({nmbrUpdate}) => {
  switch (nmbrUpdate.eventType) {
    case 'employee_enrolled':
      return <EmployeeEnrolled nmbrUpdate={nmbrUpdate}/>;
    case 'employee_updated':
      return <EmployeeUpdated nmbrUpdate={nmbrUpdate}/>;
    case 'employment_record_created':
    case 'employment_record_updated':
    case 'employment_record_destroyed':
      return <PayRateUpdated nmbrUpdate={nmbrUpdate}/>;
    case 'on_leave_record_created':
    case 'termination_record_created':
      return <RoeRequired nmbrUpdate={nmbrUpdate}/>;
    case 'on_leave_record_updated':
    case 'on_leave_record_destroyed':
    case 'termination_record_destroyed':
    case 'reactivation_record_created':
    case 'reactivation_record_updated':
    case 'reactivation_record_destroyed':
    case 'return_from_leave_record_created':
    case 'return_from_leave_record_updated':
    case 'return_from_leave_record_destroyed':
      return <EmployeeReturned nmbrUpdate={nmbrUpdate}/>;
    case 'tax_form_completed':
      return <TaxFormCompleted nmbrUpdate={nmbrUpdate}/>;
    default:
      throw new Error(`Unsupported event type of ${nmbrUpdate.eventType}`);
  }
});

export default NmbrUpdateFactory;
