import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {DatePicker, EmailTemplatePreview} from 'components';

const ReopenSurveyModal = observer(({uiState}) => {
  const {selectedSurvey, welcomeEmailPreview} = uiState;

  if (!selectedSurvey) return null;

  return (
    <Modal
      size='md'
      title='surveys.directory.reopen_survey.Reopen Survey'
      isOpen={uiState.reopenSurveyModalOpen}
      onHide={() => uiState.closeReopenSurveyModal()}
    >
      <div className='mb2'>
        <FormattedMessage id='surveys.directory.reopen_survey.MODAL_EXPLAINER'/>
      </div>
      <div className='mb2'>
        <FormattedMessage id='surveys.directory.reopen_survey.DUE_DATE_EXPLAINER'/>
      </div>
      <DatePicker
        value={selectedSurvey.dueDate}
        options={{startDate: '0'}}
        onChange={date => selectedSurvey.merge({dueDate: date})}
        label='surveys.directory.reopen_survey.Due date'
        optional
      />
      <div className='mt2 mb1 medium'>
        <FormattedMessage id='surveys.directory.reopen_survey.Email preview'/>
      </div>
      {selectedSurvey.incompleteStatusCount > 0 && <div className='pb2 jumbo'>
        <FormattedMessage id='surveys.directory.reopen_survey.REOPEN_TO' values={{count: selectedSurvey.incompleteStatusCount}}/>
      </div>}
      <EmailTemplatePreview template={welcomeEmailPreview}/>
      <ModalButtons
        onSave={() => uiState.reopenSurvey()}
        onCancel={() => uiState.closeReopenSurveyModal()}/>
    </Modal>
  );
});

export default ReopenSurveyModal;
