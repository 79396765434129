import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Input, FormLayout} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from "react-intl";
import {CheckboxWithLabel, EmailTemplatePreview, FormError} from "components";

const PlatformAccess = observer(({uiState}) => {
  const {skipInvite, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('employees.hire.invite.Platform Access')}/>
      <div className='mb1'>
        <CheckboxWithLabel
          label='employees.hire.invite.Invite this employee to Collage'
          checked={!skipInvite}
          onChange={(e) => uiState.skipInvite = !skipInvite}
        />
        <FormError message={errors.base}/>
      </div>
      <Panel.Separator/>
    </div>
  );
});

const AddEmployee = observer(({uiState}) => {
  const {employee, skipInvite} = uiState;

  if (!skipInvite) return null;

  return (
    <div>
      <Panel.Header
        title={t('employees.hire.invite.SKIP_EMPLOYEE_INVITE', {employee: employee.name})}
        description={t('employees.hire.invite.SKIP_EMPLOYEE_INVITE_EXPLAINER')}
      />
    </div>
  );
});

const InviteEmployee = observer(({uiState}) => {
  const {employee, skipInvite, errors} = uiState;

  if (skipInvite) return null;

  return (
    <div>
      <Panel.Header
        title={t('employees.hire.invite.INVITE_EMPLOYEE', {employee: employee.name})}
        description={t('employees.hire.invite.INVITATION_EXPLAINER')}
      />
      <FormLayout>
        <Input
          label='employees.hire.invite.Invitation Email'
          value={employee.invitationEmail}
          onChange={e => employee.merge({invitationEmail: e.target.value})}
          errorMessage={errors.invitationEmail}
        />
      </FormLayout>
      <div className='h4 pb1 medium'>
        <FormattedMessage id='employees.hire.invite.Invitation email preview'/>
      </div>
      <EmailTemplatePreview
        template={uiState.emailPreview}
      />
    </div>
  );
});

const Review = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <div>
      <Panel>
        <PlatformAccess uiState={uiState}/>
        <AddEmployee uiState={uiState}/>
        <InviteEmployee uiState={uiState}/>
      </Panel>
      <FormError className='mt1' message={errors.errorMessage}/>
    </div>
  );
});

export default Review;
