import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {auth, endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';

class SurveyDirectoryState {
  store = new DomainStore();
  history;

  @observable selectedSurvey;
  @observable closeSurveyModalOpen = false;
  @observable relaunchSurveyModalOpen = false;
  @observable reopenSurveyModalOpen = false;
  @observable welcomeEmailPreview;

  receiveProps({history}) {
    this.history = history;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async load() {
    await this.store._compose([
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);
    this.welcomeEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'survey_launch'});
  }

  @action async createSurvey() {
    const {model} = await this.store.post(
      endpoints.SURVEYS.SURVEYS,
      types.SURVEYS.SURVEY
    );

    this.history.push(`/manage/survey/${model.id}/edit`);
  }

  @computed get managesSurveys() {
    return auth.hasAccess('::MANAGE_SURVEYS');
  }

  @action async removeSurvey(survey) {
    await this.store.destroy(survey);
  }

  @action openRelaunchSurveyModal(survey) {
    this.selectedSurvey = survey;
    this.relaunchSurveyModalOpen = true;
  }

  @action closeRelaunchSurveyModal() {
    this.relaunchSurveyModalOpen = false;
    this.selectedSurvey = null;
  }

  @action openCloseSurveyModal(survey) {
    this.selectedSurvey = survey;
    this.closeSurveyModalOpen = true;
  }

  @action closeCloseSurveyModal() {
    this.closeSurveyModalOpen = false;
    this.selectedSurvey = null;
  }

  @action async closeSurvey() {
    const {model} = await this.store.patch(
      endpoints.SURVEYS.SURVEY.CLOSE.with(this.selectedSurvey.id),
      types.SURVEYS.SURVEY,
      this.selectedSurvey
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('surveys.directory.close_survey.Survey closed.'));
      this.closeCloseSurveyModal();
    }
  }

  @action async relaunchSurvey() {
    const {model} = await this.store.patch(
      endpoints.SURVEYS.SURVEY.RELAUNCH.with(this.selectedSurvey.id),
      types.SURVEYS.SURVEY,
      this.selectedSurvey
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('surveys.directory.relaunch_survey.Survey relaunched.'));
      this.closeRelaunchSurveyModal();
    }
  }

  @action async reopenSurvey() {
    const {model} = await this.store.patch(
      endpoints.SURVEYS.SURVEY.REOPEN.with(this.selectedSurvey.id),
      types.SURVEYS.SURVEY,
      this.selectedSurvey
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('surveys.directory.reopen_survey.Survey reopened.'));
      this.closeReopenSurveyModal();
    }
  }

  @action openReopenSurveyModal(survey) {
    this.selectedSurvey = survey;
    this.reopenSurveyModalOpen = true;
  }

  @action closeReopenSurveyModal() {
    this.selectedSurvey = null;
    this.reopenSurveyModalOpen = false;
  }

  customLinksFor(survey) {
    const links = [];
    if (survey.hasLink('relaunch')) {
      links.push({
        order: 0,
        text: 'surveys.directory.relaunch_survey.Relaunch',
        action: model => this.openRelaunchSurveyModal(model)
      });
    }
    if (survey.hasLink('close')) {
      links.push({
        order: 1,
        text: 'surveys.directory.close_survey.Close',
        action: model => this.openCloseSurveyModal(model)
      });
    }
    if (survey.hasLink('reopen')) {
      links.push({
        order: 0,
        text: 'surveys.directory.reopen_survey.Reopen',
        action: model => this.openReopenSurveyModal(model)
      });
    }

    return links;
  }

  surveyPath(survey) {
    switch (survey.state) {
      case 'created':
        return `/manage/survey/${survey.id}/edit`;
      default:
        return `/manage/survey/${survey.id}/`;
    }
  }

  get canViewClosed() {
    return auth.hasAccess('::MANAGE_SURVEYS');
  }
}

export default SurveyDirectoryState;
