import React from 'react';
import {observer} from 'mobx-react';
import {auth, withState, t} from 'shared/core';
import {HRDashboardPageState} from '../state';
import {Layout, Display, Panel} from 'components';
import {Feed} from '../feed';
import {Insights} from '../insights';
import {CompanyDetails} from '../company_details';
import {CompanyLinks} from '../company_links';
import {TimeOff} from '../time_off';
import {OutOfOffice} from '../out_of_office';
import {PayrollDates} from '../payroll_dates';
import {NmbrPayrollInfo} from '../nmbr_payroll_dates';
import CompanyFiles from '../company_files/components/CompanyFiles';

const HRDashboardPage = observer(({uiState}) => {
  const {
    Emoji, celebrations, staffCount, timeOffAccounts, ptoRequests, sortedCompanyLinks, folderlessFiles, companyFileFolders, company,
    payPeriod, paySchedules, loadingPaySchedules, payScheduleErrors, insightsInboxSummary, insightsPTOSummary, insightsNewHireSummary, insightsJobApplicantSummary
  } = uiState;

  return (
    <React.Fragment>
      <Display
        title={uiState.greeting}
        documentTitle={t('hr_dashboard.Home')}
      />
      <Layout>
        <div className='HRDashboardWidgets nm'>
          {auth.user.admin && <Insights
            Emoji={Emoji}
            inboxSummary={insightsInboxSummary}
            ptoSummary={insightsPTOSummary}
            newHireSummary={insightsNewHireSummary}
            jobApplicantSummary={insightsJobApplicantSummary}
          />}
          <Panel.Stack loose className='sidebar'>
            <CompanyDetails staffCount={staffCount} initialCompany={company}/>
            {auth.employee && auth.employee.hasTimeOffPolicy && <TimeOff Emoji={Emoji} accounts={timeOffAccounts}/>}
            <PayrollDates Emoji={Emoji} payPeriod={payPeriod}/>
            {auth.company.nmbrEnabled && <NmbrPayrollInfo Emoji={Emoji} paySchedules={paySchedules} isLoading={loadingPaySchedules} errors={payScheduleErrors}/>}
            {auth.user.peopleOffTodayVisibility && <OutOfOffice Emoji={Emoji} ptoRequests={ptoRequests}/>}
            <CompanyLinks Emoji={Emoji} companyLinks={sortedCompanyLinks}/>
            <CompanyFiles Emoji={Emoji} folderlessFiles={folderlessFiles} companyFileFolders={companyFileFolders}/>
          </Panel.Stack>
          <Feed Emoji={Emoji} celebrations={celebrations}/>
        </div>
      </Layout>
    </React.Fragment>
  );
});

export default withState(HRDashboardPage, HRDashboardPageState);
