import React from 'react';
import {observer} from 'mobx-react';
import {Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import _ from 'lodash';

const BatchEnrolEmployeesResultsModal = observer(({modalOpen, uiState}) => {
  const {enrolInProgress, enrolledEmployees, erroredEmployees} = uiState;

  return (
    <Modal
      size='md'
      title={'nmbr.employees.directory.Batch Enrol Employees'}
      isOpen={modalOpen}
    >
      {
        enrolInProgress ?
          <div>
            <FormattedMessage id='nmbr.employees.directory.Enrol in progress. This may take a couple minutes.'/>
            <Spinner/>
          </div>
          :
          <div>
            {!_.isEmpty(enrolledEmployees) && <div className='mb2'>
              <FormattedMessage id='nmbr.employees.directory.Successfully Enrolled Employees:'/>
              <ul className='mb2'>
                {
                  enrolledEmployees.map(enrolledEmployee => <li className='ml2'>{enrolledEmployee.name}</li>)
                }
              </ul>
            </div>
            }
            {!_.isEmpty(erroredEmployees) && <div>
              <FormattedMessage id='nmbr.employees.directory.Enrolments With Errors:'/>
              <ul>
                {
                  erroredEmployees.map(erroredEmployee => <li className='ml2'>{`${erroredEmployee.name} - ${erroredEmployee.errorMessage}`}</li>)
                }
              </ul>
            </div>
            }
          </div>
      }
      <ModalButtons
        cancelCaption='nmbr.employees.directory.Close'
        onCancel={() => uiState.closeBatchEnrolEmployeesResultsModal()}
        hideSave
      />
    </Modal>
  );
});

export default BatchEnrolEmployeesResultsModal;
