import React from 'react';
import {observer} from 'mobx-react';
import {withState, auth, t} from 'shared/core';
import {NmbrPayrollInfoState} from '../state';
import {FormattedMessage} from 'react-intl';
import {Table, Panel, Tag, EmojiTitle, Spinner, QuickTip, ContactUsLink} from 'components';
import _ from 'lodash';

const EmptyState = () => {
  return (
    <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id={'hr_dashboard.nmbr_payroll_info.No pay schedules to show.'}/>
    </div>
  );
};

const ErrorState = () => {
  return (
    <QuickTip
      trait='error'
      header={t('Something went wrong.')}
      paragraphs={[
        <FormattedMessage id='ERROR_BOUNDARY_DESC' values={{contactUs: <ContactUsLink/>}}/>
      ]}
    />
  );
};

const PayrollDate = observer(({model}) => {
  return (
    <div>
      <div className={model.colour}>
        {model.daysFromNow < 0 ?
          <FormattedMessage id='hr_dashboard.nmbr_payroll_info.DAYS_AGO' values={{daysFromNow: Math.abs(model.daysFromNow)}}/> :
          <FormattedMessage id='hr_dashboard.nmbr_payroll_info.DAYS_FROM_NOW' values={{daysFromNow: model.daysFromNow}}/>}
      </div>
      <div className='waterloo'>
        {model.date}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 7,
    render: model => t(`hr_dashboard.nmbr_payroll_info.${model.dateName}`)
  },
  {
    width: 5,
    component: PayrollDate
  }
];

const PayrollDates = observer(({payroll, uiState, showHeader}) => {
  return (
    <div className='mb2'>
      {showHeader && <div className='medium flex'>
        <div className='mr1'><FormattedMessage id={`hr_dashboard.nmbr_payroll_info.payroll_type.${payroll.type}`}/></div>
        <Tag colour={uiState.payrollStatusColor(payroll.status)}>
          <FormattedMessage id={`hr_dashboard.nmbr_payroll_info.payroll_status.${payroll.status}`}/>
        </Tag>
      </div>}
      <Table
        columns={COLUMNS}
        showLinks={false}
        showHeaders={false}
        indexBy='dateName'
        models={uiState.payrollToArray(payroll)}
      />
    </div>
  );
});

const PaySchedules = observer(({paySchedules, uiState}) => {
  return (
    paySchedules.map((paySchedule, index) => <div>
      <div className='h3 medium mb1 flex'>
        <div className='mr1'>{paySchedule.title}</div>
        {paySchedule.nextPayrolls.length === 1 && <Tag colour={uiState.payrollStatusColor(paySchedule.nextPayrolls[0].status)}>
          <FormattedMessage id={`hr_dashboard.nmbr_payroll_info.payroll_status.${paySchedule.nextPayrolls[0].status}`}/>
        </Tag>}
      </div>
      {paySchedule.sortedNextPayrolls.map(payroll => <PayrollDates
        payroll={payroll}
        uiState={uiState}
        showHeader={paySchedule.nextPayrolls.length > 1}
      />)}
      {index < paySchedules.length - 1 && <Panel.Separator/>}
    </div>)
  );
});

const EmployeePayDate = observer(({payroll}) => {
  return (
    <div className='h2'>
      {payroll.payDateDaysFromNow < 0 ?
        <FormattedMessage id='hr_dashboard.nmbr_payroll_info.DAYS_AGO' values={{daysFromNow: Math.abs(payroll.payDateDaysFromNow)}}/> :
        <FormattedMessage id='hr_dashboard.nmbr_payroll_info.CAPITAL_DAYS_FROM_NOW' values={{daysFromNow: payroll.payDateDaysFromNow}}/>}
      <div className='h3 waterloo'>{payroll.payDateView}</div>
    </div>
  );
});

const Content = observer(({uiState}) => {
  const {isLoading, paySchedules, errors} = uiState;

  if (isLoading) return <Spinner/>;
  if (errors) return <ErrorState/>;
  if (_.isEmpty(paySchedules)) return <EmptyState/>;

  return auth.hasAccess('::PAYROLL') ?
    <PaySchedules paySchedules={paySchedules} uiState={uiState}/> :
    <EmployeePayDate payroll={paySchedules[0].nextPayrolls[0]}/>;
});

const NmbrPayrollInfo = observer(({uiState}) => {
  const {Emoji} = uiState;
  const title = auth.hasAccess('::PAYROLL') ? 'hr_dashboard.nmbr_payroll_info.Payroll' : 'hr_dashboard.nmbr_payroll_info.Next Pay Date';

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='moneybag' title={t(title)}/>}/>
      <Content uiState={uiState}/>
    </Panel>
  );
});

export default withState(NmbrPayrollInfo, NmbrPayrollInfoState);
