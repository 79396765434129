import React from 'react';
import {observer} from 'mobx-react';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';
import {Table, QuickTip} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const COLUMNS = [
  {
    header: 'nmbr.updates.directory.Effective Date',
    width: 3,
    render: model => calendarDate(model.effectiveDate)
  },
  {
    header: 'nmbr.updates.directory.Old Pay Rate',
    width: 3,
    render: model => t(
      'nmbr.updates.directory.SALARY',
      {
        value: model.oldRate,
        frequency: t(`nmbr.updates.directory.pay_frequencies.${model.oldType}`)
      }
    )
  },
  {
    header: 'nmbr.updates.directory.Old Hours',
    width: 1.5,
    render: model => model.oldHours
  },
  {
    header: 'nmbr.updates.directory.New Pay Rate',
    width: 3,
    render: model => t(
      'nmbr.updates.directory.SALARY',
      {
        value: model.newRate,
        frequency: t(`nmbr.updates.directory.pay_frequencies.${model.newType}`)
      }
    )
  },
  {
    header: 'nmbr.updates.directory.New Hours',
    width: 1.5,
    render: model => model.newHours
  },
];

const PayRateUpdated = observer(({nmbrUpdate}) => {
  return (
    <div>
      <div className='mb3'>
        <div className='mb1 h3'><FormattedMessage id='nmbr.updates.directory.Pay Rates'/></div>
        <Table
          columns={COLUMNS}
          models={nmbrUpdate.fieldUpdates}
          showLinks={false}
        />
      </div>
      {nmbrUpdate.retroactivePayUpdate &&
        <QuickTip
          trait='error'
          header={t('nmbr.updates.directory.Retroactive Changes Not Applied')}
          paragraphs={[t(
            'nmbr.updates.directory.RETROACTIVE_CHANGES_WARNING',
            {
              effectiveDate: calendarDate(nmbrUpdate.retroactivePayUpdate.effectiveDate),
              cutoffDate: calendarDate(nmbrUpdate.retroactivePayUpdate.cutoffDate)
            }
          )]}
        />}
    </div>
  );
});

export default PayRateUpdated;
