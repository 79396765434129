import {observable} from 'mobx';

class NmbrPayrollInfoState {
  Emoji;

  @observable paySchedules = [];
  @observable isLoading = false;
  @observable errors = false;

  receiveProps({isLoading, paySchedules, errors, Emoji}) {
    this.paySchedules = paySchedules;
    this.isLoading = isLoading;
    this.errors = errors;
    this.Emoji = Emoji;
  }

  approvalDueDateColour(payroll) {
    if (payroll.status === 'approved' || payroll.approvalDueDateDaysFromNow > 2) {
      return null;
    } else if (payroll.approvalDueDateDaysFromNow === 2) {
      return 'sun';
    } else if (payroll.approvalDueDateDaysFromNow <= 1) {
      return 'scarlet';
    }
  }

  payrollToArray(payroll) {
    return [
      {
        dateName: 'Payroll Approval Due Date',
        date: payroll.approvalDueDateView,
        daysFromNow: payroll.approvalDueDateDaysFromNow,
        colour: this.approvalDueDateColour(payroll)
      },
      {
        dateName: 'Pay Date',
        date: payroll.payDateView,
        daysFromNow: payroll.payDateDaysFromNow
      }
    ];
  }

  payrollStatusColor(status) {
    switch (status) {
      case 'approved':
      case 'paid':
      case 'processing':
      case 'partially_paid':
        return 'grass';
      case 'draft':
        return 'turquoise';
      case 'failed':
        return 'scarlet';
      default:
        throw new Error(`Status ${status} is not supported.`);        
    }
  }
}

export default NmbrPayrollInfoState;
