import React from 'react';
import {observer} from 'mobx-react';
import {Panel, ActionLink} from 'components';
import {ReviewRow} from 'components/flow';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import EmailTemplatePreviewModal from 'components/modals/EmailTemplatePreviewModal';

 const Review = observer(({uiState}) => {
  const emailPreviewModal = useModal(uiState, 'EmailPreviewModal');
  const {match, survey, welcomeEmailPreview} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('surveys.edit.Review your survey')}
        description={t("surveys.edit.Hover over a section and click edit to make changes.")}
      />
      <ReviewRow title='surveys.edit.Survey Name' value={survey.name} editRoute={`/manage/survey/${match.params.id}/edit/name`} />
      <ReviewRow title='surveys.edit.Anonymous Responses' value={survey.anonymous ? t('Yes') : t('No') } editRoute={`/manage/survey/${match.params.id}/edit/name`}/>
      <ReviewRow title='surveys.edit.steps.Questions' editRoute={`/manage/survey/${match.params.id}/edit/questions`}>
        <ul className='list-reset list-gap-1'>
          {survey.questions.map((q, index) =>
            <li key={index}>{`${index + 1}. ${q.text}`}</li>)}
        </ul>
      </ReviewRow>
      <ReviewRow lastRow title='surveys.edit.Email'>
        <div className='flex'>
          <FormattedMessage id='surveys.edit.Survey launch email to employees'/>
          <ActionLink className='ml2' onClick={() => uiState.openEmailPreviewModal()}>
            <FormattedMessage id='surveys.edit.preview'/>
          </ActionLink>
        </div>
      </ReviewRow>
      <EmailTemplatePreviewModal
        title='surveys.edit.Launch email to employees'
        isOpen={emailPreviewModal}
        onHide={() => uiState.closeEmailPreviewModal()}
        template={welcomeEmailPreview}
      />
    </div>
  );
});

 export default Review;
