import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Table} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const COLUMNS = [
  {
    header: 'nmbr.updates.directory.Field',
    width: 4,
    render: model => t(`nmbr.updates.directory.fields.${model.field}`)
  },
  {
    header: 'nmbr.updates.directory.Old Value',
    width: 4,
    attribute: 'oldValue'
  },
  {
    header: 'nmbr.updates.directory.New Value',
    width: 4,
    attribute: 'newValue'
  }
];

const EmployeeUpdated = observer(({nmbrUpdate}) => {
  return (
    <div>
      <div className='mb3'>
        <div className='mb1 h3'><FormattedMessage id='nmbr.updates.directory.Employee Fields'/></div>
        <Table
          columns={COLUMNS}
          models={nmbrUpdate.fieldUpdates}
          showLinks={false}
        />
      </div>
    </div>
  );
});

export default EmployeeUpdated;
