import {DomainObject, oneOf} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {daysFromNow, calendarDate} from 'shared/tools';

class NmbrPayroll extends DomainObject {
  @observable status;
  @observable approvalDueDate;
  @observable payDate;
  @observable type;

  @action merge(other, _) {
    super.merge(other, {
      status: oneOf(['draft', 'approved', 'paid', 'failed', 'partially_paid', 'processing']),
      type: oneOf(['regular', 'off_cycle', 'historical'])
    });
  }

  @computed get approvalDueDateView() {
    return calendarDate(this.approvalDueDate);
  }

  @computed get approvalDueDateDaysFromNow() {
    return daysFromNow(this.approvalDueDate);
  }

  @computed get payDateView() {
    return calendarDate(this.payDate);
  }

  @computed get payDateDaysFromNow() {
    return daysFromNow(this.payDate);
  }
}

export default NmbrPayroll;
