import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import InteractiveContextState from 'components/tables/interactive/state/InteractiveContextState';
import PropTypes from 'prop-types';
import Spinner from 'components/spinners/Spinner';
import _ from 'lodash';

@observer class InteractiveContext extends React.Component {
  @observable uiState;

  async componentWillMount() {
    const {
      proxy,
      initialFilter,
      initialSorting,
      onRefresh,
      onMount,
      onFilterUpdated,
      saveStateToUrl
    } = this.props;

    this.uiState = new InteractiveContextState(proxy, onRefresh, onMount, onFilterUpdated, saveStateToUrl);
    await this.uiState.load({initialFilter, initialSorting});
  }

  async componentWillReceiveProps({initialFilter, initialSorting, proxy, updateRequested}) {
    if (proxy.endpoint !== this.uiState.proxy.endpoint) {
      this.uiState.setProxy(proxy);
      updateRequested = true;
    }
    if (updateRequested) {
      await this.uiState.load({initialFilter: _.merge(initialFilter, this.uiState.filter), initialSorting: _.merge(initialSorting, this.uiState.sorting)});
    }
  }

  render() {
    const {children, empty, ...rest} = this.props;
    const {uiState} = this;

    if (uiState.isEmpty && _.isFunction(empty)) {
      const EmptyState = empty;
      return <EmptyState/>;
    }

    if (uiState.isLoadingFirstTime) {
      return <Spinner/>;
    }

    return (
      <div>
        {React.Children.map(children, child =>
          child && React.cloneElement(child, {
            ...rest,
            isUpdating: uiState.isUpdating,
            models: uiState.models,
            filter: uiState.filter,
            pagination: uiState.pagination,
            sorting: uiState.sorting,
            filterUpdated: filter => uiState.updateFilter(filter),
            filterDeleted: filterKey => uiState.deleteFilter(filterKey),
            paginationUpdated: pagination => uiState.updatePagination(pagination),
            sortingUpdated: sorting => uiState.updateSorting(sorting),
            modelsUpdated: () => uiState.refresh(),
            defaultFilterPropagated: filter => uiState.updateDefaultFilter(filter)
          })
        )}
      </div>
    );
  }
}

InteractiveContext.propTypes = {
  proxy: PropTypes.object.isRequired,
  empty: PropTypes.func
};

// InteractiveContext

// props:

// - onMount: will be called after the initial load. accepts a single argument (agent).
//   agent has the following api:
//     - refresh(): causes the InteractiveContext to refresh the datasource
// - empty: a component to render if there is no models satisfying the default criteria.
//   when InteractiveContext loads data initially, if all the conditions below are true:
//     1. there is no data received from the api (models.length === 0), and
//     2. there is no pagination or sorting applied, and
//     3. the filter object is either empty or a subset of the default filter object,
//   then InteractiveContext will render the special empty state component defined in this prop.

export default InteractiveContext;
