import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const EmployeeDirectoryStatus = observer(({model}) => {
  const employee = model;

  return (
    <div className='md-and-below-hide'>
      <FormattedMessage id={`employees.directory.employee_status.${employee.employmentType}`}/>
    </div>
  );
});

export default EmployeeDirectoryStatus;
