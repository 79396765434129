import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {calendarDate} from 'shared/tools';
import {Table} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const FIELD_COLUMNS = [
  {
    header: 'nmbr.updates.directory.Field',
    width: 6,
    render: model => t(`nmbr.updates.directory.fields.${model.field}`)
  },
  {
    header: 'nmbr.updates.directory.Value',
    width: 6,
    attribute: 'newValue'
  }
];

const PAY_RATE_COLUMNS = [
  {
    header: 'nmbr.updates.directory.Effective Date',
    width: 6,
    render: model => calendarDate(model.effectiveDate)
  },
  {
    header: 'nmbr.updates.directory.Pay Rate',
    width: 3,
    render: model => t(
      'nmbr.updates.directory.SALARY',
      {
        value: model.newRate,
        frequency: t(`nmbr.updates.directory.pay_frequencies.${model.newType}`)
      }
    )
  },
  {
    header: 'nmbr.updates.directory.Hours',
    width: 3,
    render: model => model.newHours
  }
];

const EmployeeEnrolled = observer(({nmbrUpdate}) => {
  return (
    <div>
      {_.filter(nmbrUpdate.fieldUpdates, {type: 'field'}).length > 0 && <div className='mb3'>
        <div className='mb1 h3'><FormattedMessage id='nmbr.updates.directory.Employee Fields'/></div>
        <Table
          columns={FIELD_COLUMNS}
          models={_.filter(nmbrUpdate.fieldUpdates, {type: 'field'})}
          showLinks={false}
        />
      </div>}
      {_.filter(nmbrUpdate.fieldUpdates, {type: 'pay_rate'}).length > 0 && <div>
        <div className='mb1 h3'><FormattedMessage id='nmbr.updates.directory.Pay Rates'/></div>
        <Table
          columns={PAY_RATE_COLUMNS}
          models={_.filter(nmbrUpdate.fieldUpdates, {type: 'pay_rate'})}
          showLinks={false}
        />
      </div>}
    </div>
  );
});

export default EmployeeEnrolled;
