import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {UploadFiles, Layout, FormLayout, Submit, ActionLink, Panel, Input, DatePicker, FormError} from 'components';
import {t, withState, auth} from 'shared/core';
import OnboardBankingState from '../state/OnboardBankingState';
import BankingChequeModal from 'employees/profile/components/banking/components/BankingChequeHelperModal';
import {observable} from 'mobx';

const SinFields = observer(({uiState}) => {
  const {employee, errors} = uiState;

  if (!employee.collectSin || !auth.company.sinEnabled) return null;

  return (
    <FormLayout>
      <Input value={employee.sin}
        onChange={e => employee.merge({sin: e.target.value})}
        className='full-width'
        errorMessage={errors.sin}
        label='onboard.banking.Social Insurance Number'/>
      <DatePicker disabled={!uiState.isTemporaryResident}
        value={uiState.sinExpiryDate}
        label='employees.profile.banking.Date of Expiry (SIN)'
        errorMessage={_.get(errors, 'sinExpiryDate')}
        onChange={date => employee.merge({sinExpiryDate: date})}
      />
    </FormLayout>
  );
});

const BankingFields = observer(({uiState}) => {
  const {employee, errors} = uiState;
  const {bankAccount} = employee;

  if (!employee.collectPayroll || !auth.company.bankingEnabled) return null;

  return (
    <FormLayout>
      <div>
        <FormattedMessage id='onboard.banking.The bank account you enter here will be used for the following purposes:'/>
        <ul className='ml3'>
          <li><FormattedMessage id='onboard.banking.To set up direct deposit for you with your employer’s payroll service'/></li>
          <li><FormattedMessage id='onboard.banking.If your company provides benefits, to set up direct deposit for claims and payments from your insurance company'/></li>
        </ul>
      </div>
      <Input value={bankAccount.nickname}
        onChange={e => bankAccount.merge({nickname: e.target.value})}
        className='full-width'
        errorMessage={_.get(errors, 'bankAccount.nickname')}
        label='onboard.banking.Bank Account Nickname'/>
      <Input value={bankAccount.transitNumber}
        onChange={e => bankAccount.merge({transitNumber: e.target.value})}
        className='full-width'
        errorMessage={_.get(errors, 'bankAccount.transitNumber')}
        label='onboard.banking.Transit Number'/>
      <Input value={bankAccount.institutionNumber}
        onChange={e => bankAccount.merge({institutionNumber: e.target.value})}
        className='full-width'
        errorMessage={_.get(errors, 'bankAccount.institutionNumber')}
        label='onboard.banking.Institution Number'/>
      <Input value={bankAccount.accountNumber}
        onChange={e => bankAccount.merge({accountNumber: e.target.value})}
        className='full-width'
        errorMessage={_.get(errors, 'bankAccount.accountNumber')}
        label='onboard.banking.Account Number'/>
      {auth.company.payrollEnabled && <div>
        <FormattedMessage id='onboard.banking.Upload a cheque'/>
        <UploadFiles
          defaultFiles={bankAccount.cheque ? observable([bankAccount.cheque]) : observable([])}
          onChange={(files) => uiState.updateFile(files)}
          maxFiles={1}
          accept={['image/*', 'application/pdf']}
          errorMessage={_.get(errors, 'bankAccount.cheque')}
          modelType='bank_account/cheque'
        />
      </div>}
      <ActionLink className='TextLink dodger' onClick={() => uiState.openBankingChequeHelperModal()}>
        <FormattedMessage id='onboard.banking.How do I find my banking information from a cheque?'/>
      </ActionLink>
      <BankingChequeModal uiState={uiState}/>
    </FormLayout>
  );
});

const OnboardBankingContainer = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <Layout>
      <Panel>
        <Panel.Header title={t('onboard.banking.Banking and Payroll Information')}/>
        <SinFields uiState={uiState}/>
        <BankingFields uiState={uiState}/>
        <FormError message={_.get(errors, 'bankAccount.base')}/>
      </Panel>
      <div className='my3'>
        <Submit size='lg' onClick={() => uiState.nextStep()}>
          <FormattedMessage id='onboard.banking.Next step'/>
        </Submit>
      </div>
    </Layout>
  );
});

export default withState(OnboardBankingContainer, OnboardBankingState);
