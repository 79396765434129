import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import NmbrPayroll from './NmbrPayroll';
import _ from 'lodash';

class NmbrPaySchedule extends DomainObject {
  @observable title;
  @observable nextPayrolls = [];

  @action merge(other, _) {
    super.merge(other, {
      nextPayrolls: [NmbrPayroll]
    });
  }

  @computed get sortedNextPayrolls() {
    return _.sortBy(this.nextPayrolls, p => p.type === 'regular' ? 0 : 1);
  }
}

export default NmbrPaySchedule;
