const types = {
  USER: 'users',
  RECOVERY_CODE: 'recoveryCodes',

  COMPANY: 'companies',

  EMPLOYEE: 'employeeSummaries',
  EMPLOYMENT_RECORD: 'employmentRecords',
  TERMINATION_RECORD: 'terminationRecords',
  REACTIVATION_RECORD: 'reactivationRecords',
  ON_LEAVE_RECORD: 'onLeaveRecords',
  EMPLOYEE_DETAILS: 'employees',
  EMPLOYEE_PLATFORM_USAGE: 'employeePlatformUsages',
  VISIBILITY_SETTING: 'visibilitySettings',

  PERFORMANCE: {
    CHECKIN: 'performanceManagementCheckins',
    CHECKIN_RELATIONS: 'checkinRelations',
    CYCLE: 'performanceManagementCycles'
  },

  TASK: 'tasks',

  TASK_TEMPLATE: 'taskTemplates',

  TRAINING: {
    PROGRAM: 'trainingPrograms',
    EMPLOYEE_TRAINING_STATUS: 'trainingEmployeeTrainingStatuses',
    EMPLOYEE_TRAINING_STATUS_SUMMARY: 'trainingEmployeeTrainingStatusSummaries'
  },

  RECRUITING: {
    CANDIDATE: 'candidates',
    CANDIDATE_SUMMARY: 'candidateSummaries',
    POSITION: 'positions',
    POSITION_RULE: 'positionRule',
    JOB_SITE: {
      SETTING: 'jobSiteSettings',
      INVALID_INDEED_LOCATION: 'invalidIndeedLocations'
    },
    CANDIDATE_TAG: 'candidateTags',
    TIMELINE: {
      FEEDBACK: 'feedback',
      STAGE_CHANGE: 'stageChanges',
      EMAIL: 'emails',
      POSITION_CHANGE: 'positionChanges',
      UPLOAD: 'candidateInternalUploads',
      QUALIFICATION_STATUS_CHANGE: 'qualificationStatusChanges',
      OFFER: 'offerActions',
      RATING: 'candidateRating',
      INTERVIEW_GUIDE_RESPONSE: 'interviewGuideResponses'
    }
  },

  DEPARTMENT: 'departments',

  FOLDER: 'folders',

  COMPANY_EMAIL_TEMPLATE: 'companyEmailTemplates',

  LOCATION: 'locations',

  PRESIGNER: 'presignedPosts',

  SECURE_FILE: 'secureFiles',

  COMPANY_LINK: 'companyLinks',
  COMPANY_FILE: 'companyFiles',
  COMPANY_FILE_FOLDER: 'companyFileFolders',

  CUSTOM_FIELD: 'customFields',
  CUSTOM_FIELD_GROUP: 'customFieldGroups',
  EMPLOYEE_CUSTOM_FIELD: 'employeeCustomFields',

  TIME_OFF: {
    POLICY: 'timeOffPolicies',
    POLICY_SUMMARY: 'timeOffPolicySummaries',
    TYPE_POLICY: 'typePolicies',
    ACCOUNT: 'timeOffAccounts',
    REQUEST: 'timeOffRequests',
    TRANSACTION: 'timeOffTransactions',
    ASSIGNMENT: 'timeOffRequestAssignments',
    TYPE: 'timeOffTypes',
    ACCOUNT_SUMMARY: 'timeOffAccountSummaries',
    TYPE_POLICY_CONFIRMATION_SUMMARY: 'typePolicyConfirmationSummaries',
    CONFLICT: 'timeOffConflicts',
    REQUEST_FORECAST: 'timeOffRequestForecasts',
    POLICY_CHANGE_FORECAST: 'timeOffPolicyChangeForecasts',
    ABSENCE: 'timeOffAbsences',
    BLOCKED_DATES: 'timeOffBlockedDates'
  },

  TIME_TRACKING: {
    POLICY: 'timeTrackingPolicies',
    PROJECT: 'timeTrackingProjects',
    TIMESHEET: 'timesheets',
    ENTRY: 'timesheetEntries',
    PAY_PERIOD: 'timeTrackingPayPeriods',
    PAY_SCHEDULE: 'timeTrackingPaySchedules',
    PAYROLL_HOURS_SUMMARY: 'timeTrackingPayrollHoursSummaries',
    REPORT_DOWNLOAD: 'timeTrackingReportDownloads'
  },

  BENEFITS_INVOICE: 'benefitsInvoices',

  ROLE: 'roles',

  COUNTRY: 'countries',
  REGION: 'regions',

  HOLIDAY: 'holidays',

  BILLING: {
    SUBSCRIPTION: 'billingSubscriptionSummary',
    CHARGIFY_DIRECT_PARAMETERS: 'chargifyDirectParameters'
  },

  NOTES: 'notes',

  GLOBAL_SEARCH: 'globalSearch',

  DOCUMENT: 'documents',
  COMPANY_DOCUMENT: 'companyDocuments',
  PENDING_DOCUMENT: 'pendingDocuments',
  DOCUMENT_ACTION: 'documentActions',
  COMPANY_DOCUMENT_ASSIGNMENT: 'companyDocumentAssignments',
  COMPANY_SIGNATURE: 'signatures',
  TAX_DOCUMENT_ASSIGNMENT: 'taxDocumentAssignments',

  REMINDER: 'reminderRules',

  COMPANY_INSURANCE_CARRIER: 'companyInsuranceCarriers',
  ENROLMENT_SUMMARY: 'enrolmentSummaries',

  BENEFIT_INFO: 'benefitInfos',

  EMAIL_TEMPLATE: 'emailTemplates',

  INTERVIEW_GUIDE: 'interviewGuides',
  INTERVIEW_GUIDE_QUESTION: 'interviewGuideQuestions',

  OFFER_TEMPLATE: 'offerTemplates',
  OFFER_TEMPLATE_SUMMARY: 'offerTemplateSummaries',
  OFFER: 'offers',
  OFFER_SUMMARY: 'offerSummaries',

  USER_DETAIL: 'userDetails',

  PAYROLL_UPLOAD: 'payrollUploads',
  PAYROLL_UPDATE: 'payrollUpdates',
  PAYROLL_EMPLOYEE_SUMMARY: 'payrollEmployeeSummary',
  PAYROLL_DIFF: 'payrollDiffs',

  CUSTOM_REPORT: 'customReports',

  REPORT_DOWNLOAD: 'reportDownloads',

  PAY_PERIOD: 'payPeriods',

  ANNOUNCEMENT: 'announcements',
  ANNOUNCEMENT_COMMENT: 'announcementComments',
  ANNOUNCEMENT_REACTION: 'announcementReactions',
  EMPLOYEE_ANNOUNCEMENT: 'employeeAnnouncements',

  PERFORMANCE_GOALS: {
    GOAL: 'goals',
    EMPLOYEE_DIRECTORY: 'employeeGoalDirectories',
    METRIC: 'metrics',
    TIMELINE: {
      COMMENT: 'goalComments',
      CURRENT_VALUE_UPDATE: 'currentValueUpdates',
      GOAL_CREATED: 'goalCreated',
    }
  },

  PERFORMANCE_REVIEWS: {
    CYCLE: 'reviewCycles',
    REVIEW: 'reviewReviews',
    REVIEWEE: 'reviewReviewees',
    TYPE: 'reviewTypes',
    ANSWER: 'reviewAnswers',
    QUESTION: 'reviewQuestions',
    QUESTION_WITH_TYPE: 'reviewQuestionsWithType',
    TEMPLATE: 'reviewTemplates',
    NOTE: 'reviewNotes',
    RESULT: 'reviewCycleResults',
  },

  SURVEYS: {
    SURVEY: 'surveys',
    RESPONSE: 'surveyResponses',
    ANSWER: 'surveyAnswers',
    QUESTION: 'surveyQuestions',
    EMPLOYEE_STATUS: 'surveyEmployeeStatuses',
    RESULT: 'surveyResults',
  },

  NAVIGATION_SCOPE: 'tabBars',

  BENEFIT_CLASS: 'benefitClasses',
  BENEFIT_DIVISION: 'benefitDivisions',
  GROUP_BENEFIT_COVERAGE: 'groupBenefitCoverages',
  EMPLOYEE_BENEFIT_ENROLLMENT: 'employeeBenefitEnrollments',
  EMPLOYEE_COVERAGE: 'employeeCoverages',

  BENEFITS: {
    UPDATE: 'benefitUpdates',
    BENEFIT_INFO_CHANGE_REQUEST: 'benefitInfoChangeRequests'
  },

  CALENDAR: 'calendars',
  EMPLOYEE_CALENDAR: 'employeeCalendars',
  SEGMENT_CALENDAR: 'segmentCalendars',
  CALENDAR_SUBSCRIPTION: 'calendarSubscriptions',

  LEAVE_TYPE: 'leaveTypes',

  INTEGRATIONS: {
    EXTERNAL_CANDIDATE: 'externalCandidates',
    EXTERNAL_CANDIDATES_SYNC_STATUS: 'externalCandidatesSyncStatuses'
  },

  HR_DASHBOARD: {
    ANNIVERSARY: 'anniversaries',
    BIRTHDAY: 'birthdays',
    FIRST_DAY: 'firstDays',
    STAFF_COUNTS: 'hrDashboardStaffCounts',
    INSIGHTS: {
      INBOX_SUMMARY: "hrDashboardInsightsInbox",
      PTO_SUMMARY: "hrDashboardInsightsPTO",
      JOB_APPLICANT_SUMMARY: "hrDashboardInsightsJobApplicants",
      NEW_HIRE_SUMMARY: "hrDashboardInsightsNewHires"
    }
  },

  EMPLOYEE_GROUP: 'employeeGroups',

  PA_DASHBOARD: {
    COMPANY_CONTACT: 'companyContacts',
    COMPANY_PLAN: 'companyPlans',
    KEY_DATE: 'keyDates'
  },

  ATTACHED_DOCUMENT: 'attachedDocuments',
  BROKER: 'brokers',
  BROKER_PORTAL: {
    BROKER_CLIENT: 'brokerClients',
    BROKER_CLIENT_INSURANCE_CARRIER: 'brokerClientInsuranceCarriers',
    BROKER_CLIENT_PLAN_ADMIN: 'brokerClientPlanAdmins',
    BROKER_INVITATION: 'brokerInvitations',
    CLIENT_COMPANY: 'clientCompanies',
    INSURANCE_CARRIER_REP: 'insuranceCarrierReps'
  },

  MY_ACCOUNT: 'myAccounts',

  USER_INVITATION: 'userInvitations',

  ADMIN_INVITATION: 'adminInvitations',

  INVITATION_VALIDATION: 'invitationValidations',

  AUTHENTICATION_RESULT: 'authenticationResults',

  DRAFT: 'drafts',

  MASS_UPDATE: 'massUpdates',
  MASS_UPDATE_SUMMARY: 'massUpdateSummary',
  MASS_UPDATE_EMPLOYEE: 'massUpdateEmployee',
  SSO: {
    OKTA_CREDENTIALS: 'oktaCredentials',
    GOOGLE_CREDENTIALS: 'googleCredentials',
    MICROSOFT_CREDENTIALS: 'microsoftCredentials'
  },

  NMBR: {
    PAY_SCHEDULE: 'nmbrPaySchedules',
    PAYROLL: 'nmbrPayrolls',
    PAY_STUB: 'nmbrPayStubs',
    UPDATE: 'nmbrUpdates'
  },

  USER_API_KEY: 'userApiKeys'
};

export default types;
