import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import NmbrEmployeesDirectoryWithState from './NmbrEmployeesDirectoryWithState';

const NmbrEmployeesDirectory = observer(() => {
  return (
    <div>
      <Subnav/>
      <NmbrEmployeesDirectoryWithState/>
    </div>
  );
});

export default NmbrEmployeesDirectory;
