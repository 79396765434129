import {Employee} from '../employees';
import {computed, observable} from 'mobx';
import _ from 'lodash';

class NmbrDirectoryEmployee extends Employee {
  @observable missingFields = [];

  @computed get configured() {
    return this.nmbrPayrollEnabled && _.isEmpty(this.missingFields);
  }
}

export default NmbrDirectoryEmployee;
