import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PayrollUpdatesContainer from './PayrollUpdatesContainer';
import {auth} from 'shared/core';
import {redirect} from 'shared/tools';

const PayrollUpdatesPage = observer(() => {
  if (auth.company.payrollEnabled) {
    redirect('/payroll');
    return null;
  }

  return (
    <Router basename='/payroll_updates'>
      <Route path='/' render={() => <PayrollUpdatesContainer/>}/>
    </Router>
  );
});

export default PayrollUpdatesPage;
