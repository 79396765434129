import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {api, types, endpoints, auth, t} from 'shared/core';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import {withDeleted, redirect} from 'shared/tools';
import {TimeOffPolicy} from 'stores/time_off';
import _ from 'lodash';
import STEPS from './steps';
import {Steps, successAlert} from 'shared/tools';
import {EmployeeCustomField} from 'stores/employee_custom_fields';
import {TrainingProgram} from 'stores/training';
import {NmbrPaySchedule} from 'stores/nmbr';

/*global document */
function injectPdfJs() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.min.js';

  document.getElementsByTagName('head')[0].appendChild(script);

  script.onload = () => {
    const viewer_script = document.createElement('script');
    viewer_script.type = 'text/javascript';
    viewer_script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf_viewer.min.js';

    document.getElementsByTagName('head')[0].appendChild(viewer_script);
  };

  const stylesheet = document.createElement('link');
  stylesheet.type = 'text/css';
  stylesheet.rel = 'stylesheet';
  stylesheet.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf_viewer.min.css';

  document.getElementsByTagName('head')[0].appendChild(stylesheet);
}

const LANGUAGE_OPTIONS = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'fr',
    name: 'Français'
  }
];

class HireFlowState {
  store = new DomainStore();
  history;

  @observable match;
  @observable employee = new EmployeeDetails();
  @observable errors = {};
  @observable locations = [];
  @observable departments = [];
  @observable employees = [];
  @observable employeePersonalCustomFields = [];
  @observable employeeJobCustomFields = [];
  @observable trainingPrograms = [];
  @observable currentStep;
  @observable skipInvite = false;
  @observable emailPreviewModalOpen = false;
  @observable notInterestedInPayroll = false;
  @observable payrollNotificationSent = false;
  @observable interestedInPayrollModalOpen = false;
  @observable benefitsNotificationSent = false;
  @observable notInterestedInBenefits = false;
  @observable interestedInBenefitsModalOpen = false;
  @observable skipHoursPerWeek = false;

  languageOptions = LANGUAGE_OPTIONS;

  @action async load() {
    const endpointsToLoad = [
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS,
      endpoints.EMPLOYEES.ALL,
      endpoints.TIME_OFF.POLICIES.CONFIGURED,
      endpoints.TRAINING.NEW_HIRE_PROGRAMS,
      endpoints.COMPANY_EMAIL_TEMPLATES,
    ];

    if (!this.isNew) {
      endpointsToLoad.push(
        endpoints.EMPLOYEE_DETAILS.with(this.match.params.id)
      );
    }

    await this.store._compose(endpointsToLoad);

    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
    this.employees = this.store.getEmployees();
    this.timeOffPolicies = this.store._getAll(types.TIME_OFF.POLICY, TimeOffPolicy);
    this.trainingPrograms = this.store._getAll(types.TRAINING.PROGRAM, TrainingProgram);

    this.emailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'employee_invitation'});

    if (!this.isNew) {
      this.__updateEmployee(
        this.store._getSingle(types.EMPLOYEE_DETAILS)
      );
    }

    await this.redirectToEmployeeProfileIfAlreadyHired();

    this.steps = new Steps(this.getFilteredSteps());
  }

  getFilteredSteps() {
    const locationsToSkip = [];
    if (auth.company.payrollEnabled) {
      locationsToSkip.push('payroll');
    }
    if (!this.trainingPrograms.length) {
      locationsToSkip.push('training');
    }
    return _.reject(STEPS, step => _.includes(locationsToSkip, step.location));
  }

  async redirectToEmployeeProfileIfAlreadyHired() {
    if (this.employee.draft || this.isNew) return null;

    return redirect(`/employees/${this.employee.id}`);
  }

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async submitEmployment() {
    const attributes = [
      'firstName',
      'lastName',
      'jobTitle',
      'location',
      'department',
      'manager',
      'startDate',
      'employmentType',
      'timeOffPolicy'
    ];

    if (this.isNew) {
      return this.__postEmployment(attributes);
    }

    return this.__submitStep(attributes);
  }

  @action async loadNmbrPaySchedules() {
    if (!auth.company.nmbrEnabled) return;

    await this.store._compose([
      endpoints.NMBR.PAY_SCHEDULES.ALL
    ]);

    this.paySchedules = this.store._getAll(
      types.NMBR.PAY_SCHEDULE,
      NmbrPaySchedule
    );
  }

  @action async loadCustomFields() {
    this.store.invalidate(types.EMPLOYEE_CUSTOM_FIELD);

    await this.store._compose([
      endpoints.EMPLOYEE_CUSTOM_FIELDS.HIRING.with(this.match.params.id)
    ]);

    this.employeePersonalCustomFields = _.sortBy(
      this.store._getAll(types.EMPLOYEE_CUSTOM_FIELD, {category: 'personal'}, EmployeeCustomField),
      f => f.createdAt
    );
    this.employeeJobCustomFields = _.sortBy(
      this.store._getAll(types.EMPLOYEE_CUSTOM_FIELD, {category: 'job'}, EmployeeCustomField),
      f => f.createdAt
    );
  }

  @action wipeoutBonusAndCommissionDetailsIfNecessary() {
    if (!this.employee.bonus) {
      this.employee.bonusStructure = null;
    }
    if (!this.employee.commission) {
      this.employee.commissionStructure = null;
    }
  }

  @action async submitCompensation() {
    if (!this.validateHoursPerWeek()) return false;

    this.wipeoutBonusAndCommissionDetailsIfNecessary();
    const collectBanking = this.employee.payrollEnabled && auth.company.bankingEnabled;
    const collectSin = this.employee.payrollEnabled && auth.company.sinEnabled;
    this.employee.merge(
      {
        updateCompensation: true,
        collectTax: this.employee.payrollEnabled,
        collectPayroll: collectBanking,
        collectSin: collectSin
      }
    );
    const attributes = [
      'payRate',
      'payRateUnit',
      'payFrequency',
      'payRateCurrency',
      'hoursPerWeek',
      'commission',
      'annualizedCommission',
      'bonus',
      'bonusStructure',
      'commissionStructure',
      'updateCompensation',
      'payrollEnabled',
      'nmbrPayrollEnabled',
      'nmbrPayScheduleId',
      'collectPayroll',
      'collectSin',
      'collectTax'
    ];

    return this.__submitStep(attributes);
  }

  @action async submitBenefits() {
    return this.__submitStep([
      'employeeBenefitEnrollments',
      'employeeInsuranceNumbers'
    ]);
  }

  @action async submitPayroll() {
    return this.__submitStep([]);
  }

  @action async submitBankingAndTax() {
    this.employee.merge({updateBankingAndTax: true});
    return this.__submitStep([
      'collectTax',
      'collectPayroll',
      'collectSin',
      'taxLocale',
      'updateBankingAndTax'
    ]);
  }

  @action async submitDocuments() {
    return this.__submitStep([
      'onboardingDocuments'
    ]);
  }

  @action async submitTasks() {
    return this.__submitStep([
      'onboardingTasks'
    ]);
  }

  @action async submitCustomFields() {
    this.employee.employeeCustomFields = [...this.employeePersonalCustomFields];
    return this.__submitStep([
      'employeeCustomFields'
    ]);
  }

  @action async submitJobCustomFields() {
    this.employee.employeeCustomFields = [...this.employeeJobCustomFields];
    return this.__submitStep([
      'employeeCustomFields'
    ]);
  }

  @action async submitTraining() {
    return this.__submitStep([
      'trainingPrograms'
    ]);
  }

  @action async submitInvite() {
    const attributes = [
      'finishConfig',
      'skipInvite'
    ];
    if (!this.skipInvite) {
      attributes.push('invitationEmail');
    }

    this.employee.merge({
      finishConfig: true,
      skipInvite: this.skipInvite
    });

    const result = await this.__submitStep(attributes);
    if (result !== false) {
      await redirect(`/employees/${this.employee.id}`);
    }
  }

  @action async deleteDraft() {
    await this.store.destroy(this.employee);
    successAlert(t('employees.hire.Draft employee has been deleted.'));
    this.history.push('/');
  }

  @action async onStepChanged({location}) {
    this.currentStep = location;

    switch (location) {
      case 'compensation':
        await this.loadNmbrPaySchedules();
        break;
      case 'documents':
        injectPdfJs();
        break;
      case 'custom_fields':
      case 'job_custom_fields':
        await this.loadCustomFields();
        break;
      case 'banking':
        this.initializeTaxLocale();
        break;
      default:
        break;
    }

    return null;
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'employment':
        return this.submitEmployment();
      case 'compensation':
        return this.submitCompensation();
      case 'payroll':
        return this.submitPayroll();
      case 'benefits':
        return this.submitBenefits();
      case 'job_custom_fields':
        return this.submitJobCustomFields();
      case 'custom_fields':
        return this.submitCustomFields();
      case 'training':
        return this.submitTraining();
      case 'banking':
        return this.submitBankingAndTax();
      case 'documents':
        return this.submitDocuments();
      case 'tasks':
        return this.submitTasks();
      case 'review':
        return this.submitInvite();
      default:
        throw new Error(`Location ${location} is not supported.`);
    }
  }

  @action setSelectedTasks(tasks) {
    this.employee.onboardingTasks = tasks;
  }

  @action setSelectedDocuments(documents) {
    this.employee.onboardingDocuments = documents;
  }

  @action setBenefitEnrolments(enrolments) {
    this.employee.employeeBenefitEnrollments = enrolments;
  }

  @action setEmployeeInsuranceNumbers(insuranceNumbers) {
    this.employee.employeeInsuranceNumbers = insuranceNumbers;
  }

  @action setSelectedTraining(programs) {
    this.employee.trainingPrograms = programs;
  }

  @action initializeTaxLocale() {
    if (!this.employee.taxLocale) {
      this.employee.merge({taxLocale: auth.locale});
    }
  }

  @action openEmailPreviewModal() {
    this.emailPreviewModalOpen = true;
  }

  @action closeEmailPreviewModal() {
    this.emailPreviewModalOpen = false;
  }

  @action updatePayrollInterest(value) {
    this.notInterestedInPayroll = value;
  }

  @action async sendPayrollNotification(value) {
    await api.post(endpoints.PAYROLL.INTERESTED, {payrollServiceType: 'managed_payroll', source: 'hire_flow'});
    this.interestedInPayrollModalOpen = true;
    this.payrollNotificationSent = true;
  }

  @action closeInterestedInPayrollModal() {
    this.interestedInPayrollModalOpen = false;
  }

  @action updateBenefitsInterest(value) {
    this.notInterestedInBenefits = value;
  }

  @action async sendBenefitsNotification(value) {
    await api.post(endpoints.BENEFITS.INTERESTED, {benefitServiceType: 'benefits_sync', source: 'hire_flow'});
    this.interestedInBenefitsModalOpen = true;
    this.benefitsNotificationSent = true;
  }

  @action closeInterestedInBenefitsModal() {
    this.interestedInBenefitsModalOpen = false;
  }

  @action toggleSkipHoursPerWeek(checked) {
    this.skipHoursPerWeek = checked;
    this.employee.hoursPerWeek = null;
  }

  @computed get isNew() {
    const id = parseInt(this.match.params.id);
    return !id;
  }

  @computed get locationsWithDeleted() {
    return withDeleted(this.locations, _.get(this.employee, 'location'));
  }

  @computed get departmentsWithDeleted() {
    return withDeleted(this.departments, _.get(this.employee, 'department'));
  }

  @computed get availableManagers() {
    return _.reject(this.employees, {
      id: this.employeeId
    });
  }

  @computed get nextEnabled() {
    if (this.currentStep === 'payroll') {
      return this.notInterestedInPayroll || this.payrollNotificationSent;
    }
    if (this.currentStep === 'benefits' && !auth.company.benefitsEnabled) {
      return this.notInterestedInBenefits || this.benefitsNotificationSent;
    }

    if (this.currentStep === 'review') {
      return !!this.employee.invitationEmail || this.skipInvite;
    }

    return true;
  }

  @computed get isLocationInCanada() {
    return this.employee.location.countryCode === 'CA';
  }

  @computed get employeeTaxForms() {
    const {regionCode} = this.employee.location;

    const taxForms = [ t('employees.hire.Federal TD1') ];

    if (regionCode) {
      taxForms.push(t(`employees.hire.provinces TD1.${regionCode}`));
    }

    if (this.employee.commission) {
      taxForms.push('TD1X');
    }

    return taxForms;
  }

  @computed get collectTax() {
    return this.employee.collectTax && this.isLocationInCanada;
  }

  @computed get locationHasRegionCode() {
    return !!this.employee.location.regionCode;
  }

  async __postEmployment(attributes) {
    const result = await this.__submitStep(
      attributes,
      this.store.post,
      endpoints.EMPLOYEES.ALL,
      types.EMPLOYEE_DETAILS
    );

    if (result === false) return false;

    return {
      nextLocation: `/hire/${this.employee.id}/compensation`
    };
  }

  async __submitStep(attributes, _func, ..._args) {
    const employee = this.employee.pick(attributes);
    const promise = _func ? _func.call(this.store, ..._args, employee) : this.store.patch(employee);
    const {model, errors} = await promise;
    this.errors = errors;
    if (!model) return false;
    this.__updateEmployee(model);
  }

  __updateEmployee(model) {
    this.employee.update(model);
    if (this.employee.managerId) {
      this.employee.manager = _.find(this.employees, {id: this.employee.managerId});
    }
  }

  validateHoursPerWeek() {
    if (this.employee.hoursPerWeek === null && !this.skipHoursPerWeek) {
      this.errors = { hoursPerWeek: t('employees.profile.role.Hours per week is required') };
      return false;
    }

    return true;
  }
}

export default HireFlowState;
