import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import CompanyDocument from 'stores/documents/CompanyDocument';

class PendingDocumentStore extends DomainStore {
  async loadPendingDocument(id) {
    try {
      await this._compose([
        endpoints.PENDING_DOCUMENT.with(id)
      ]);

      return new CompanyDocument(this._getSingle(types.PENDING_DOCUMENT));
    } catch (e) {
      return e;
    }
  }

  async markDocumentComplete(companyDocument, signature, annotations) {
    const doc = companyDocument.pick([]);
    const annotationValues = this.prepareAnnotationsForSave(annotations, signature);

    doc.merge({signature});
    doc.merge({annotationValues});

    return await this.patch(doc);
  }

  prepareAnnotationsForSave(annotations, signature) {
    return annotations.map(a => {
      return {
        id: a.annotation.annotationArgs.id,
        annotationType: a.annotation.annotationType,
        value: a.annotation.annotationType === 'input' ? a.value : signature,
        isTotalClaimAmount: a.annotation.isTotalClaimAmount,
        annotationArgs: {
          fontSize: a.annotation.annotationArgs.fontSize
        }
      };
    });
  }
}

const singleton = new PendingDocumentStore();

export default singleton;
