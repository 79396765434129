import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {auth, t, types, endpoints} from 'shared/core';
import {Company} from 'stores/company';
import {CompanyLink} from 'stores/company_links';
import {Anniversary, Birthday, FirstDay} from 'stores/celebrations';
import {StaffCounts} from 'stores/hr_dashboard/';
import {dateToJson} from 'shared/tools';
import moment from 'moment';
import {TimeOffRequest} from 'stores/time_off';
import {TimeOffAccount} from 'stores/time_off';
import _ from 'lodash';
import * as Sentry from "@sentry/react";
import {CompanyFile, CompanyFileFolder} from 'stores/company_files';
import {NmbrPaySchedule} from 'stores/nmbr';

class HRDashboardPageState {
  Emoji;

  @observable announcements = [];
  @observable anniversaries = [];
  @observable birthdays = [];
  @observable company;
  @observable firstDays = [];
  @observable staffCount;
  @observable timeOffAccounts = [];
  @observable ptoRequests = [];
  @observable companyLinks = [];
  @observable folderlessFiles = [];
  @observable companyFileFolders = [];
  @observable payPeriod = {};
  @observable paySchedules = [];
  @observable loadingPaySchedules = false;
  @observable payScheduleErrors = false;
  @observable insightsInboxSummary;
  @observable insightsPTOSummary;
  @observable insightsNewHireSummary;
  @observable insightsJobApplicantSummary;

  store = new DomainStore();

  endpoints() {
    const result = [
      endpoints.HR_DASHBOARD.CELEBRATIONS,
      endpoints.COMPANY_LINKS.ALL,
      endpoints.COMPANY_FILES.with(),
      endpoints.COMPANY_FILE_FOLDERS.ALL,
      endpoints.CURRENT_COMPANY
    ];

    if (auth.user.staffCountVisibility) {
      result.push(endpoints.HR_DASHBOARD.STAFF_COUNTS);
    }

    if (auth.user.peopleOffTodayVisibility) {
      result.push(endpoints.TIME_OFF.PEOPLE_OFF.with(dateToJson(moment().toDate())));
    }

    if (auth.employee && auth.employee.hasTimeOffPolicy) {
      result.push(endpoints.TIME_OFF.ACCOUNTS.with(auth.employee.id));
    }

    if (auth.user.admin) {
      result.push(endpoints.HR_DASHBOARD.INSIGHTS.INBOX_SUMMARY);
      result.push(endpoints.HR_DASHBOARD.INSIGHTS.NEW_HIRE_SUMMARY);
      result.push(endpoints.HR_DASHBOARD.INSIGHTS.PTO_SUMMARY);
      result.push(endpoints.HR_DASHBOARD.INSIGHTS.JOB_APPLICANT_SUMMARY);
    }

    if (auth.hasAccess('::PAYROLL') && auth.company.payrollEnabled) {
      result.push(endpoints.PAY_PERIOD.NEXT_PAY_DATE_PERIOD);
    }

    if (auth.hasAccess('::MANAGE_ANNOUNCEMENTS')) {
      result.push(endpoints.EMPLOYEES.ALL);
    }

    return result;
  }

  @action populateStore() {
    this.company = new Company(this.store._getSingle(types.COMPANY));
    this.anniversaries = this.store._getAll(types.HR_DASHBOARD.ANNIVERSARY, Anniversary);
    this.birthdays = this.store._getAll(types.HR_DASHBOARD.BIRTHDAY, Birthday);
    this.firstDays = this.store._getAll(types.HR_DASHBOARD.FIRST_DAY, FirstDay);
    this.insightsInboxSummary = this.store._getSingle(types.HR_DASHBOARD.INSIGHTS.INBOX_SUMMARY);
    this.insightsPTOSummary = this.store._getSingle(types.HR_DASHBOARD.INSIGHTS.PTO_SUMMARY);
    this.insightsNewHireSummary = this.store._getSingle(types.HR_DASHBOARD.INSIGHTS.NEW_HIRE_SUMMARY);
    this.insightsJobApplicantSummary = this.store._getSingle(types.HR_DASHBOARD.INSIGHTS.JOB_APPLICANT_SUMMARY);
    this.staffCount = new StaffCounts(this.store._getSingle(types.HR_DASHBOARD.STAFF_COUNTS));
    this.companyLinks = this.store._getAll(types.COMPANY_LINK, CompanyLink);
    this.folderlessFiles = this.store._getAll(types.COMPANY_FILE, CompanyFile);
    this.companyFileFolders = this.store._getAll(types.COMPANY_FILE_FOLDER, CompanyFileFolder);
    this.timeOffAccounts = this.store._getAll(types.TIME_OFF.ACCOUNT).map(account => new TimeOffAccount(account));
    this.ptoRequests = this.store._getAll(types.TIME_OFF.REQUEST).map(request => new TimeOffRequest(request));
    this.payPeriod = this.store._getSingle(types.PAY_PERIOD);
  }

  @action async load() {
    if ((auth.hasAccess('::PAYROLL') || auth.employee.hasNmbrPayroll) && auth.company.nmbrEnabled) {
      this.loadPaySchedules();
    }

    const _endpoints = this.endpoints();
    const [responses, module] = await Promise.all([
      this.store._compose(_endpoints, {failSilently: true}),
      import(/* webpackChunkName: 'emoji-mart-async' */ 'emoji-mart')
        .catch(e => {
          if(!(/Loading chunk .* failed/.test(e.message))){
            throw e;
          }
      })
    ]);

    this.populateStore();
    if (module) {
      this.Emoji = module.Emoji;
    }

    _.each(responses, r => {
      if (!r.failedSilently) return;

      Sentry.captureException(r.e);
    });
  }

  @action async loadPaySchedules() {
    this.loadingPaySchedules = true;

    try {
      await this.store._compose([
        endpoints.NMBR.PAY_SCHEDULES.WITH_NEXT_PAYROLLS
      ]);
      this.paySchedules = this.store._getAll(types.NMBR.PAY_SCHEDULE, NmbrPaySchedule);
    } catch(_) {
      this.payScheduleErrors = true;
    } finally {
      this.loadingPaySchedules = false;
    }
  }

  get greeting() {
    const currentHours = new Date().getHours();
    if (currentHours < 12) {
      return t('hr_dashboard.greetings.GOOD_MORNING', {name: auth.user.firstName});
    }
    if (currentHours < 18) {
      return t('hr_dashboard.greetings.GOOD_AFTERNOON', {name: auth.user.firstName});
    }

    return t('hr_dashboard.greetings.GOOD_EVENING', {name: auth.user.firstName});
  }

  @computed get celebrations() {
    return _.concat(
      this.anniversaries.slice(),
      this.birthdays.slice(),
      this.firstDays.slice()
    );
  }

  @computed get sortedCompanyLinks(){
    return _.sortBy(this.companyLinks, 'order');
  }
}

export default HRDashboardPageState;
