import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const EmployeeProfileSubnav = ({uiState}) => {
  const {match} = uiState;

  return (
    <ul className='Subnav-list list-reset'>
      <li><NavLink to={`${match.url}/personal`} activeClassName='active'><FormattedMessage id='employees.profile.Personal'/></NavLink></li>
      {uiState.tabs.job &&
        <li><NavLink to={`${match.url}/job`} activeClassName='active'><FormattedMessage id='employees.profile.Job'/></NavLink></li>}
      {uiState.tabs.timeOff &&
        <li><NavLink to={`${match.url}/time-off`} activeClassName='active'><FormattedMessage id='employees.profile.Time Off'/></NavLink></li>}
      {(uiState.tabs.payroll || uiState.tabs.banking) &&
        <li><NavLink to={`${match.url}/banking`} activeClassName='active'><FormattedMessage id={uiState.tabs.payroll ? 'employees.profile.Payroll' : 'employees.profile.Banking'}/></NavLink></li>}
      {uiState.tabs.emergencyContact &&
        <li><NavLink to={`${match.url}/emergency`} activeClassName='active'><FormattedMessage id='employees.profile.Emergency'/></NavLink></li>}
      {uiState.tabs.documents &&
        <li><NavLink to={`${match.url}/documents`} activeClassName='active'><FormattedMessage id='employees.profile.Documents'/></NavLink></li>}
      {uiState.tabs.notes &&
        <li><NavLink to={`${match.url}/notes`} activeClassName='active'><FormattedMessage id='employees.profile.Notes'/></NavLink></li>}
      {uiState.tabs.calendar &&
        <li><NavLink to={`${match.url}/calendars`} activeClassName='active'><FormattedMessage id='employees.profile.Calendars'/></NavLink></li>}
      {uiState.tabs.onboarding &&
        <li><NavLink to={`${match.url}/onboarding`} activeClassName='active'><FormattedMessage id='employees.profile.Onboarding'/></NavLink></li>}
    </ul>
  );
};

export default EmployeeProfileSubnav;
