import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink} from 'components';
import {observable} from 'mobx';
import {FormattedMessage} from 'react-intl';

const BalanceDetails = observer(({account}) => {
  if (account.unlimited) {
    return (
      <div>
        <div className='flex mt1'>
          <div style={{fontSize: 22}} className='TimeOffBalances-itemNumber tutarta mr1 col-2'>{'--'}</div>
          <div className='TimeOffBalances-itemTitle align-self-end col-10'>
            <FormattedMessage id='employees.profile.time_off.Days Available'/>
          </div>
        </div>
        <div className='flex mt1'>
          <div style={{fontSize: 22, minWidth: '16.6666667%'}} className='TimeOffBalances-itemNumber tutarta mr1'>{account.logged}</div>
          <div className='TimeOffBalances-itemTitle align-self-end'>
            <FormattedMessage id='employees.profile.time_off.Days Logged'/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={`TimeOffBalances-itemNumber ${account.isNegativeBalance ? 'scarlet' : 'tuatara'}`}>{account.balanceView}</div>
      <div className='TimeOffBalances-itemTitle'>
        <FormattedMessage id={account.balanceDescription}/>
      </div>
    </React.Fragment>
  );
});

const Item = observer(({uiState, account}) => {
  return (
    <div
      className='TimeOffBalances-item'
      onClick={() => uiState.openPolicySummaryModal(account)}
    >
      <div className='TimeOffBalances-typeName'>
        <div className='table-cell align-middle'>{account.timeOffType.name}</div>
      </div>
      <BalanceDetails account={account}/>
    </div>
  );
});

class TimeOffBalances extends React.Component {
  @observable scrollState = {
    showScrolling: false,
    scrolling: null
  }

  componentDidMount() {
    const {accounts} = this.props.uiState;
    const width = this.container.getBoundingClientRect().width;
    if (accounts.length * 225 > width) {
      this.scrollState.showScrolling = true;
    }
  }

  scrollLeft = (e) => {
    e.preventDefault();
    const container = this.container;
    const scrolling = setInterval(function() {
      container.scrollLeft -= 15;
    }, 26);

    this.scrollState.scrolling = scrolling;
  }

  scrollRight = (e) => {
    e.preventDefault();
    const container = this.container;
    const scrolling = setInterval(function() {
      container.scrollLeft += 15;
    }, 26);

    this.scrollState.scrolling = scrolling;
  }

  clearScrolling = () => {
    clearInterval(this.scrollState.scrolling);
  }

  render() {
    const {uiState} = this.props;
    const {scrollLeft, scrollRight, clearScrolling, scrollState} = this;

    return (
      <div className='TimeOffBalances'>
        {scrollState.showScrolling && <ActionLink className='TimeOffBalances-scroll--left jumbo' onMouseEnter={scrollLeft} onMouseLeave={clearScrolling}><i className='material-icons'>{'keyboard_arrow_left'}</i></ActionLink>}
        <div className='TimeOffBalances-carousel' ref={container => this.container = container}>
          {uiState.accounts.map(account => <Item key={account.id} account={account} uiState={uiState}/>)}
        </div>
        {scrollState.showScrolling && <ActionLink className='TimeOffBalances-scroll--right jumbo' onMouseEnter={scrollRight} onMouseLeave={clearScrolling}><i className='material-icons'>{'keyboard_arrow_right'}</i></ActionLink>}
      </div>
    );
  }
}

export default observer(TimeOffBalances);
