import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const RoeRequired = observer(({nmbrUpdate}) => {
  return (
    <div>
      <div className='mb1 h3'><FormattedMessage id={`nmbr.updates.directory.${nmbrUpdate.eventType}.WHAT_HAPPENS`}/></div>
      <FormattedMessage id={`nmbr.updates.directory.${nmbrUpdate.eventType}.EXPLAINER`}/>
      <ul>
        <li className='ml2'>
          <FormattedMessage id={`nmbr.updates.directory.${nmbrUpdate.eventType}.BULLET_1`}/>
        </li>
        <li className='ml2'>
          <FormattedMessage id={`nmbr.updates.directory.${nmbrUpdate.eventType}.BULLET_2`}/>
        </li>
        {nmbrUpdate.eventType === 'termination_record_created' && <li className='ml2 mb1'>
          <FormattedMessage id={`nmbr.updates.directory.${nmbrUpdate.eventType}.BULLET_3`}/>
        </li>}
      </ul>
      <a href='https://support.collage.co/en/articles/nowhere' target='_blank'>
        <FormattedMessage id='nmbr.updates.directory.Learn more'/>
      </a>
    </div>
  );
});

export default RoeRequired;
