import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Table, Select2, Spinner, FormLayout} from 'components';
import {t} from 'shared/core';
import EffectiveDate from './EffectiveDate';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const PredictedAccountsSummary = observer(({uiState}) => {
  const {isForecastingEmployee, predictedAccountRows, predictedAccountColumns} = uiState;

  if (_.isEmpty(predictedAccountRows)) return <Spinner/>;

  return (
    <Table
      models={predictedAccountRows}
      columns={predictedAccountColumns}
      isUpdating={isForecastingEmployee}
      showLinks={false}
    />
  );
});

const Preview = observer(({uiState, employees, updateForecast}) => {
  const {forecastDateOptions, effectiveDate} = uiState;
  if (!effectiveDate) return null;

  return (
    <div className='p2 border border--submarine rounded'>
      <div className='h3 medium mb1'>
        <FormattedMessage id='time_off.policy.edit.Preview your changes'/>
      </div>
      <FormLayout>
        <div className='h4 jumbo'>
          <FormattedMessage id="time_off.policy.edit.In the table below you can view what a given employee's balance will be before and after this change."/>
        </div>
        <Select2
          label='time_off.policy.edit.Preview changes for'
          value={uiState.forecastingEmployeeId}
          onChange={e => uiState.updateForecastingEmployee(e.target.value, updateForecast)}
          searchable
        >
          {employees.map(employee =>
            <option key={employee.id} value={employee.id}>{employee.name}</option>)
          }
        </Select2>
        <Select2
          label='time_off.policy.edit.Preview balance on'
          value={uiState.forecastDate}
          onChange={e => uiState.updateForecastDate(e.target.value, updateForecast)}
        >
          {forecastDateOptions.map(forecastDateOption =>
            <option key={forecastDateOption.label} value={forecastDateOption.date}>{t(forecastDateOption.label, {date: forecastDateOption.dateView})}</option>)
          }
        </Select2>
      </FormLayout>
      <PredictedAccountsSummary uiState={uiState}/>
    </div>
  );
});

const PolicyConfirmationModal = observer(({uiState, description, isOpen, onSave, employees, updateForecast}) => {
  return (
    <Modal
      size='md'
      isOpen={isOpen}
      closeButton={false}
    >
      <div className='h3 medium mb1'>
        <FormattedMessage id='time_off.policy.edit.confirmation.Changing time off policy'/>
      </div>
      <div className='h4 mb2 jumbo'>
        <FormattedMessage id={description}/>
      </div>
      <EffectiveDate uiState={uiState} updateForecast={updateForecast}/>
      <Preview uiState={uiState} employees={employees} updateForecast={updateForecast}/>
      <ModalButtons
        onCancel={() => uiState.closePolicyConfirmationModal()}
        onSave={onSave}
        saveEnabled={uiState.effectiveDate}
        saveCaption='time_off.policy.edit.confirmation.Continue'
      />
    </Modal>
  );
});

export default PolicyConfirmationModal;
